import styled from "styled-components"

import { fonts } from "../../../styles"

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  > button {
    width: 100%;
    background: transparent;
    cursor: pointer;
    border: 0;
    outline: none;
  }
`

export const Wrapper = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${props => props.theme.backgrounds.orange};
  padding: 15px 10px;

  > h1 {
    color: ${props => props.theme.colors.white};
    font-size: ${fonts.small}px;
    font-weight: 600;
    line-height: 160%;
    max-width: 90%;
  }
`

export const Collapse = styled.div`
  /* background: ${props => props.theme.colors.white}; */
  display: ${({ isOpened }) => (isOpened ? "block" : "none")};
  background: transparent;
  padding: 10px;

  p {
    font-size: ${fonts.small}px;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    line-height: 160%;
    font-family: 'Roboto';
  }
`
