import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Calculadora from "../Calculadora"

import * as S from "./styles"

function MainContent() {
  const { background, mulher } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mulher: file(relativePath: { eq: "mulhercartao.png" }) {
        childImageSharp {
          fluid(maxHeight: 511, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <S.Container fluid={background.childImageSharp.fluid} alt="Background">
      <S.Wrapper>
        <S.LeftContent>
          <p>
            SAQUE NO <strong>cartão de crédito</strong>
          </p>
          <h1>
            De <span>R$300,00 a R$10.000,00</span>
          </h1>
          <h1>
            Receba em <span>1 Dia Útil</span>
          </h1>
          <Calculadora />
        </S.LeftContent>

        <S.StyledImage
          fluid={mulher.childImageSharp.fluid}
          alt="mulher cartao"
        />
      </S.Wrapper>
    </S.Container>
  )
}

export default MainContent
