import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { IoLogoWhatsapp } from "react-icons/io"

import * as S from "./styles"

function Segura() {
  const { anesp, seguro } = useStaticQuery(graphql`
    query {
      anesp: file(relativePath: { eq: "anesp.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      seguro: file(relativePath: { eq: "seguro.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <S.Container id="segura">
      <S.Wrapper>
        <h1>A GRANACRED é segura?</h1>
        <div>
          <strong>
            Sim, a <strong className="dark">Granacred</strong> é segura! Atuamos
            em parceria com a <strong className="orange">HCRED</strong>!
          </strong>
        </div>
        <div>
          <p>
            Usamos os mais rigorosos processos de controle para proteger todos
            os seus dados, inclusive os do seu cartão de crédito e, além disso,
            contamos com os certificados digitais de maior credibilidade do
            mercado.
          </p>
        </div>
        <div>
          <p>
            A Granacred já atua há 14 anos no mercado, e conta com uma equipe
            capacitada e certificada, bem como uma excelente estrutura para
            melhor lhe atender.
          </p>
        </div>
        <div>
          <span>
            Não cobramos nenhum valor antecipadamente para disponibilizar o
            empréstimo via e-mail ou telefone.
          </span>
        </div>
        <div>
          <p>Qualquer dúvida entre em contato.</p>
          <a
            href={
              "https://api.whatsapp.com/send?phone=5518996064773&text=Gostaria%20de%20tirar%20um%20d%C3%BAvida%20em%20rela%C3%A7%C3%A3o%20ao%20empr%C3%A9stimo%20no%20cart%C3%A3o."
            }
            arial-label="granacred website"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fale agora
            <IoLogoWhatsapp size={32} color="#fff" />
          </a>
        </div>

        <div className="grid">
          <S.SecurityImage
            fixed={anesp.childImageSharp.fixed}
            alt="anesp certificado"
          />
          <S.SecurityImage
            fixed={seguro.childImageSharp.fixed}
            alt="seguro certificado"
          />
        </div>
      </S.Wrapper>
    </S.Container>
  )
}

export default Segura
