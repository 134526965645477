import React from "react"
import createNumberMask from "text-mask-addons/dist/createNumberMask"

import { CustomButton } from "./styles"

const defaultMaskOptions = {
  prefix: "R$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  requireDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 5, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({
  error,
  inputRef,
  maskOptions,
  placeholder,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })
  return (
    <CustomButton
      type="tel"
      error={error ? 1 : 0}
      placeholder={placeholder}
      mask={currencyMask}
      {...inputProps}
    />
  )
}

export default CurrencyInput
