import React from "react"

import * as S from "./styles"

function WhatsButton({ url, children, ...rest }) {
  return (
    <S.Container href={url} {...rest}>
      {children}
    </S.Container>
  )
}

export default WhatsButton
