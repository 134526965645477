import styled from "styled-components"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"

import { fonts } from "../../styles"

export const Container = styled(BgImg)`
  height: 530px;
  max-height: 1191px;
  width: 100%;
  /* background-size: 100%; */
  /* background-position: 0% 50%; */
  @media (max-width: 768px) {
    height: 620px;
  }
  @media (max-width: 376px) {
    height: 660px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: none;
    }
  }
`

export const StyledImage = styled(Img)`
  position: absolute;
  bottom: 509px;
  right: -460px;
  max-width: 450px;
`
export const LeftContent = styled.div`
  position: relative;
  padding-top: 37px;
  height: 100%;
  p {
    font-size: ${fonts.superGiant}px;
    font-family: "NATS";
    color: ${props => props.theme.text.orangeText};
    letter-spacing: 0.08em;
    font-weight: bold;
    margin-bottom: 30px;

    strong {
      color: transparent;
      text-transform: uppercase;
      -webkit-text-stroke: 1px ${props => props.theme.text.orangeText};
    }
  }

  > h1 {
    margin-bottom: 30px;
    font-size: ${fonts.superGiant}px;
    font-family: "Roboto";
    color: ${props => props.theme.text.orangeText};

    span {
      font-size: ${fonts.superGiant}px;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.backgrounds.aqua};
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    p {
      text-align: center;
      font-size: ${fonts.giant}px;
    }
    > h1 {
      text-align: center;
      font-size: ${fonts.bigger}px;
      line-height: 160%;
      span {
        font-size: ${fonts.superBig - 2}px;
      }
    }
  }
  @media (max-width: 425px) {
    p {
      text-align: center;
      font-size: ${fonts.giant}px;
    }
    > h1 {
      text-align: center;
      font-size: ${fonts.bigger}px;
      span {
        font-size: ${fonts.superBig - 6}px;
      }
    }
  }
`
export const RightContent = styled.div``
