import React, { useState, useEffect } from "react"

import * as S from "./styles"

/* import Slider from "../RangeSlider" */
import WhatsButton from "../WhatsButton"
import CurrencyInput from "../CurrencyInput"

import { formatPrice } from "../../util/format"

const factors = [
  { id: 4, factor: 0.315 },
  { id: 5, factor: 0.256 },
  { id: 6, factor: 0.217 },
  { id: 7, factor: 0.188 },
  { id: 8, factor: 0.1665 },
  { id: 9, factor: 0.149 },
  { id: 10, factor: 0.135 },
  { id: 11, factor: 0.1255 },
  { id: 12, factor: 0.132695 },
]

function Calculadora() {
  const [error, setError] = useState(false)
  const [dinheiro, setDinheiro] = useState(null)
  const [parcelas, setParcelas] = useState(12)
  /* const [parcelas, setParcelas] = useState(4) */
  const [valorFinal, setValorFinal] = useState("")

  const whatsUrl = "https://api.whatsapp.com/send?phone=5518991023766"
  
  const message = `Quero trocar crédito do meu cartão por dinheiro. No valor de ${dinheiro}`
 /*  const message = `Quero contratar crédito no cartão no valor de ${dinheiro}%20em%20${parcelas}%20parcelas%20de%20${formatPrice(
    valorFinal
  )}.` */

  useEffect(() => {
    let formatedMoney = dinheiro
      ? Number(dinheiro.replace("R$", "").replace(".", "").replace(",", "."))
      : 0

    if (formatedMoney < 300) {
      setError(true)
      setValorFinal("")
    } else {
      setError(false)

      setValorFinal(
        Number(formatedMoney * factors.find(f => f.id === parcelas).factor)
      )
    }
  }, [parcelas, dinheiro, setError])

  return (
    <S.Container>
      <h1>Quanto você precisa?</h1>
      <CurrencyInput
        placeholder="Valor mínimo de R$ 300,00"
        value={dinheiro}
        onChange={e => setDinheiro(e.target.value)}
        error={error}
      />
     {/*  <Slider value={parcelas} setValue={setParcelas} /> */}
      {valorFinal && (
        <S.WrapperPrice>
          <span>{parcelas} x de </span>
          <strong>{formatPrice(valorFinal)}</strong>
        </S.WrapperPrice>
      )}
      <WhatsButton url={error ? whatsUrl : `${whatsUrl}&text=${message}`}>
        Quero Meu Saque
      </WhatsButton>
    </S.Container>
  )
}

export default Calculadora
