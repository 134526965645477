import styled from "styled-components"
import MaskedInput from "react-text-mask"

import { fonts } from "../../styles"

export const CustomButton = styled(MaskedInput)`
  outline: none;
  width: 100%;
  border-radius: 8px;
  border: 3px solid
    ${props =>
      props.error ? props.theme.text.errorText : props.theme.backgrounds.aqua};
  height: 45px;

  padding-left: 10px;
  font-size: ${fonts.small}px;
  background: white;
  color: ${props => props.theme.text.darkAquText};
  font-weight: bold;

  ::placeholder {
    color: ${props => props.theme.text.placeholder};
    opacity: 1;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
