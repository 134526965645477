import styled from "styled-components"
import Img from "gatsby-image"

import { fonts } from "../../styles"

export const Container = styled.div`
  background: ${props => props.theme.backgrounds.darkaqua};
  width: 100%;
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 1.6rem;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: ${fonts.big}px;
    color: ${props => props.theme.colors.white};
    font-family: "Roboto";
    line-height: 160%;
    font-weight: 900;
  }
  p {
    font-size: ${fonts.regular}px;
    color: ${props => props.theme.colors.white};
    font-family: "Roboto";
    line-height: 160%;
    font-weight: 500;
    text-align: justify;
  }

  & .orange {
    padding-left: 10px;
    margin-top: 10px;
    font-size: ${fonts.regular}px;
    color: red;
    color: ${props => props.theme.colors.white};
    font-family: "Roboto";
    line-height: 160%;
    font-weight: 500;
    text-align: justify;
    p {
      margin-bottom: 10px;
    }
  }

  ::before,
  & .orange {
    content: " ";
    border-left: 3px solid orange;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledImage = styled(Img)`
  width: 229px;
  max-width: 229px;
`
