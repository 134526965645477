import React from "react"

import Layout from "../components/Layout"
import MainContent from "../components/MainContent"
import AfterMainContent from "../components/AfterMainContent"
import Vantagens from "../components/Vantagens"
import ComoFunciona from "../components/ComoFunciona"
import Indicado from "../components/Indicado"
import Vantagens2 from "../components/Vantagens2"
import FAQ from "../components/Faq"
import Segura from "../components/Segura"
import Footer from "../components/Footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainContent />
    <AfterMainContent />
    <Vantagens />
    <ComoFunciona />
    <Indicado />
    <Vantagens2 />
    <FAQ />
    <Segura />
    <Footer />
  </Layout>
)

export default IndexPage
