import styled from "styled-components"

import { fonts } from "../../styles"

export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.darkaqua};
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  p {
    margin-bottom: 20px;
    font-family: "Roboto";
    font-size: ${fonts.superSmall}px;
    font-weight: 300;
    line-height: 160%;
    text-align: justify;
    color: rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 768px) {
    padding: 50px 1.6rem;
  }
`
