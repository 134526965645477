import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

function Vantagens() {
  const { handshake, handmoney, watch, handphone } = useStaticQuery(graphql`
    query {
      handshake: file(relativePath: { eq: "handshake.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      handmoney: file(relativePath: { eq: "handmoney.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      watch: file(relativePath: { eq: "watch.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      handphone: file(relativePath: { eq: "handphone.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <S.Container>
      <S.Wrapper>
        <S.WrapperCards>
          <S.CardItem>
            <S.Card fluid={handphone.childImageSharp.fluid} alt="handphone" />
            <p>100% Online</p>
          </S.CardItem>
          <S.CardItem>
            <S.Card fluid={watch.childImageSharp.fluid} alt="watch" />
            <p>Sem Consultas ao SPC/SERASA</p>
          </S.CardItem>
          <S.CardItem>
            <S.Card fluid={handshake.childImageSharp.fluid} alt="handshake" />
            <p>Mais Fácil</p>
          </S.CardItem>
          <S.CardItem>
            <S.Card fluid={handmoney.childImageSharp.fluid} alt="handmoney" />
            <p>Dinheiro na Conta em 24h</p>
          </S.CardItem>
        </S.WrapperCards>
      </S.Wrapper>
    </S.Container>
  )
}

export default Vantagens
