import styled from "styled-components"

import { fonts } from "../../styles"

export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.aqua};
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  h1 {
    font-family: "Roboto";
    font-size: ${fonts.big}px;
    color: ${props => props.theme.backgrounds.darkaqua};
    line-height: 160.9%;
    font-weight: 900;
  }

  p {
    font-family: "Roboto";
    font-size: ${fonts.regular}px;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    line-height: 160.9%;
  }

  @media (max-width: 768px) {
    padding: 3rem 1.6rem;
  }
`
