import styled from "styled-components"
import BgImg from "gatsby-background-image"

import { fonts } from "../../styles"

export const Container = styled(BgImg)`
  max-height: 1500px;
  width: 100%;
  background-color: ${props => props.theme.backgrounds.gradarkGray};
  /* background-size: 100%; */
  /* background-position: 0% 50%; */
`

export const Wrapper = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > h1 {
    text-align: center;
    color: ${props => props.theme.colors.white};
    font-size: ${fonts.big}px;
    font-weight: 900;
    line-height: 160%;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 50px 1.6rem;
  }
`
