import styled from "styled-components"
import Img from "gatsby-image"

import { fonts } from "../../styles"

export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  justify-content: center;
  grid-gap: 50px;

  @media (max-width: 768px) {
    padding: 3rem 1.6rem;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 428px) {
    grid-template-columns: 1fr;
  }
`

export const CardLinear = styled.div`
  background: linear-gradient(180deg, #52d7a3 0%, rgba(5, 141, 185, 0.65) 100%);
  padding: 5px;
  border-radius: 11px;
`

export const Card = styled.div`
  height: 100%;
  border-radius: 11px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};

  h2 {
    font-family: "Roboto";
    font-size: ${fonts.big}px;
    line-height: 24px;
    text-align: center;
    color: ${props => props.theme.backgrounds.darkaqua};
    margin: 15px 0;
  }

  p {
    font-family: "Roboto";
    font-size: ${fonts.small}px;
    line-height: 19px;
    text-align: center;
    font-weight: 300;
    color: ${props => props.theme.text.grayText};
  }
`

export const StyledImage = styled(Img).attrs({})`
  /*  height: 100%;
  width: 100%;
  max-height: 80px; */
`
