import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

function Vantagens2() {
  const { clock, calculator, home, fees } = useStaticQuery(graphql`
    query {
      clock: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      calculator: file(relativePath: { eq: "calculator.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      home: file(relativePath: { eq: "home.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fees: file(relativePath: { eq: "fees.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <S.Container>
      <S.Wrapper>
        <S.CardLinear>
          <S.Card>
            <S.StyledImage fixed={clock.childImageSharp.fixed} alt="relógio" />
            <h2>Valor Disponível em Até 1 Dia Útil</h2>
            <p>
              Quando o valor solicitado for aprovado, você recebe, em sua conta
              corrente, em até 1 dia útil.
            </p>
          </S.Card>
        </S.CardLinear>
        <S.CardLinear>
          <S.Card>
            <S.StyledImage
              fixed={calculator.childImageSharp.fixed}
              alt="calculadora"
            />
            <h2>Parcelamento em Até 12 Prestações</h2>
            <p>
              Você pode parcelar de 3 até 12 meses, o que ficar melhor para
              você.
            </p>
          </S.Card>
        </S.CardLinear>
        <S.CardLinear>
          <S.Card>
            <S.StyledImage fixed={home.childImageSharp.fixed} alt="casa" />
            <h2>Não Necessita de Garantias</h2>
            <p>
              É isso mesmo: o seu limite de cartão de crédito já faz esse papel.
            </p>
          </S.Card>
        </S.CardLinear>
        <S.CardLinear>
          <S.Card>
            <S.StyledImage fixed={fees.childImageSharp.fixed} alt="taxas" />
            <h2>Melhores taxas do Mercado</h2>
            <p>
              Oferecemos ótimas taxas, a partir de 3,59%, muito menores do que
              as praticadas pelo mercado.
            </p>
          </S.Card>
        </S.CardLinear>
      </S.Wrapper>
    </S.Container>
  )
}

export default Vantagens2
