import React from "react"

import * as S from "./styles"

function Indicado() {
  return (
    <S.Container>
      <S.Wrapper>
        <h1>Para Quem É Indicado?</h1>
        <p>
          Pessoas que possuem limite disponível no cartão de crédito e desejam
          sacar esse limite e pagar parcelado.
        </p>
        <h1>O Que Eu Preciso?</h1>
        <p>
          Você só precisa ter limite disponível no seu cartão e uma conta
          bancária de sua titularidade, além dos documentos necessários para
          aprovação.
        </p>
      </S.Wrapper>
    </S.Container>
  )
}

export default Indicado
