import styled from "styled-components"
import BgImg from "gatsby-background-image"

import { fonts } from "../../styles"

export const Container = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 40px 0 50px 0;
`

export const WrapperCards = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 428px) {
    grid-template-columns: 1fr;
  }
`

export const CardItem = styled.div`
  position: relative;

  p {
    position: absolute;
    width: 140px;
    max-height: 100px;
    padding: 10px 5px;

    bottom: -15px;
    left: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props => props.theme.backgrounds.aqua};
    color: ${props => props.theme.text.darkAquText};
    font-family: "Roboto";
    font-weight: bold;
    font-size: ${fonts.small}px;
    border-radius: 8px;
    line-height: 18px;
    text-align: center;

    @media (max-width: 428px) {
      left: 55px;
    }
  }
`

export const Card = styled(BgImg)`
  width: 200px;
  height: 200px;
  border: 3px solid ${props => props.theme.backgrounds.aqua};

  @media (max-width: 428px) {
    width: 250px;
  }
`
