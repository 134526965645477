import React, { useState } from "react"

import { FaPlus, FaMinus } from "react-icons/fa"

import * as S from "./styles"

function FaqItem({ title, description }) {
  const [isOpened, setIsOpened] = useState(false)

  function handleCollapse() {
    setIsOpened(prev => !prev)
  }

  return (
    <S.Container>
      <button onClick={handleCollapse}>
        <S.Wrapper>
          <h1>{title}</h1>
          {!isOpened ? (
            <FaPlus color="#fff" size={16} />
          ) : (
            <FaMinus color="#fff" size={16} />
          )}
        </S.Wrapper>
      </button>
      <S.Collapse isOpened={isOpened}>
        <p>{description}</p>
      </S.Collapse>
    </S.Container>
  )
}

export default FaqItem
