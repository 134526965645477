import styled from "styled-components"

import { fonts } from "../../styles"

export const Container = styled.div`
  z-index: 99;
  box-shadow: 4px 5px 5px ${props => props.theme.opacity.shadeDarkAqua};
  position: absolute;

  width: 450px;
  background: ${props => props.theme.opacity.darkaqua};
  border-radius: 8px;
  padding: 10px 20px;

  > h1 {
    font-size: ${fonts.big}px;
    text-align: center;
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const WrapperPrice = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px 0 ;

  span {
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    font-family: "Roboto";
    font-size: ${fonts.regular}px;
  }
  strong {
    color: ${props => props.theme.text.orangeText};
    font-weight: bold;
    font-size: ${fonts.bigger}px;
    font-family: "Roboto";
  }
`
