import styled from "styled-components"
import { darken } from "polished"

import { fonts } from "../../styles"

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  padding: 15px 0px;
  width: 100%;
  box-shadow: 1px 2px 5px ${props => props.theme.opacity.shadeOrange};
  cursor: pointer;
  background: ${({ background, theme }) =>
    background ? background : theme.backgrounds.orange};
  border-radius: 8px;
  border: 0;
  outline: none;
  text-decoration: none;

  font-family: "Roboto";
  font-size: ${fonts.big}px;
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.white};
  font-weight: bold;
  transition: background 0.2s ease-in-out;
  text-align: center;

  svg {
    margin-left: 5px;
  }

  &:hover {
    background: ${({ background, theme }) =>
      background ? darken(0.1, background) : theme.opacity.hover};
  }
`
