import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import WhatsButton from "../WhatsButton"
import FaqItem from "./FaqItem"

import * as S from "./styles"

function Faq() {
  const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background-gray.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const questoes = [
    {
      id: 1,
      title: "A GRANACRED faz análise do crédito pessoal?",
      description:
        "Não analisamos crédito pessoal porque usamos o limite do cartão de crédito do cliente. Isso dá maior velocidade da aprovação da operação.",
    },
    {
      id: 2,
      title: "Quais taxas vou pagar?",
      description:
        "Você não pagará nenhuma taxa além do calculado. O IOF (Imposto sobre Operações Financeiras) já está incluso.",
    },
    {
      id: 3,
      title: "Posso sacar mais que o limite do meu cartão de crédito?",
      description:
        "Não, você somente poderá sacar em média até 70% do limite disponível no seu cartão de crédito.",
    },
    {
      id: 4,
      title:
        "Como é possível usar o limite do cartão de crédito para receber dinheiro?",
      description:
        "Realizamos uma pré-autorização com a operadora do seu cartão de crédito para confirmar se você possui o limite solicitado disponível.",
    },
    {
      id: 5,
      title:
        "É possível solicitar o crédito no cartão de uma pessoa e enviar o valor solicitado para a conta bancária de outra pessoa, com CPFs diferentes?",
      description: "Não, somente para o mesmo CPF do titular do cartão.",
    },
  ]

  return (
    <S.Container
      id="faq"
      fluid={background.childImageSharp.fluid}
      alt="Background-gray"
    >
      <S.Wrapper>
        <h1>Precisa de Mais Informações?</h1>
        {questoes.map(questao => (
          <FaqItem
            key={String(questao.id)}
            title={questao.title}
            description={questao.description}
          />
        ))}
        <WhatsButton
          background="#fff"
          textColor="#FF9B05"
          url={
            "https://api.whatsapp.com/send?phone=5518996064773&text=Gostaria%20de%20sacar%20meu%20limite%20no%20cart%C3%A3o%20de%20cr%C3%A9dito."
          }
        >
          Sacar Meu Limite no Cartão de Crédito
        </WhatsButton>
      </S.Wrapper>
    </S.Container>
  )
}

export default Faq
