import styled from "styled-components"
import Img from "gatsby-image"

import { fonts } from "../../styles"

export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
  padding: 50px 0;

  @media (max-width: 768px) {
    padding: 50px 1.6rem;
  }
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 40px 20px 20px 20px;
  background: ${props => props.theme.backgrounds.aqua};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      height: 40px;
      margin-top: 10px;
      text-decoration: none;
      border: 0;
      background: #25d366;
      border-radius: 8px;
      padding: 10px;

      svg {
        margin-left: 5px;
      }
    }

    &.grid {
      margin: 20px 0 0 0;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
    }
    /*  &.flex-row {
      margin: 20px 0 0 0;
      flex-direction: row;

      > a {
        margin-left: 10px;
        text-decoration: none;
        border: 0;
        background: transparent;
      }
    } */
  }

  h1 {
    color: ${props => props.theme.backgrounds.darkaqua};
    font-weight: 900;
    font-family: "Roboto";
    font-size: ${fonts.big}px;
    text-align: center;
    margin-bottom: 20px;
  }

  strong {
    text-align: center;
    font-family: ${fonts.regular}px;
    color: ${props => props.theme.colors.white};
    font-weight: 900;
    font-family: "Roboto";

    & .dark {
      color: ${props => props.theme.backgrounds.darkaqua};
    }
    & .orange {
      color: ${props => props.theme.text.lightOrangeText};
    }
  }

  p {
    text-align: center;
    line-height: 160%;
    font-family: "Roboto";
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    font-size: ${fonts.small}px;
  }

  span {
    text-align: center;
    line-height: 160%;
    font-family: "Roboto";
    color: ${props => props.theme.text.darkGrayText};
    font-weight: bold;
    font-size: ${fonts.small}px;
  }

  /*  @media (max-width: 428px) {
    grid-template-columns: 1fr;
  }  */
`

export const SecurityImage = styled(Img)``
