import styled from "styled-components"

import { fonts } from "../../styles"

export const Container = styled.div`
  width: 100%;
  padding: 50px 0;
  background: ${props => props.theme.backgrounds.aqua};

  @media (max-width: 768px) {
    padding: 50px 1.6rem;
  }
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Info = styled.p`
  font-family: "Roboto";
  font-size: ${fonts.big}px;
  color: ${props => props.theme.colors.white};
  font-weight: 900;
  line-height: 160.9%;
`
