import React from "react"

import * as S from "./styles"

function AfterMainContent() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Info>
          Tem Limite Disponível e Precisa de Dinheiro? Faça o Saque do Seu
          Limite do Cartão de Crédito! Receba o Dinheiro em Sua Conta Bancária à
          Partir de 1 Dia Útil.{" "}
        </S.Info>
      </S.Wrapper>
    </S.Container>
  )
}

export default AfterMainContent
