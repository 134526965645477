import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

function ComoFunciona() {
  const { carteira } = useStaticQuery(graphql`
    query {
      carteira: file(relativePath: { eq: "carteira.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Container id="como-fazer">
      <S.Wrapper>
        <S.LeftContent>
          <h1>Como Funciona ?</h1>
          <p>
            A GRANACRED usa uma modalidade de saque atrelada ao seu cartão de
            crédito, utilizando o limite disponível como garantia, e o pagamento
            das parcelas é feito através da sua fatura.
          </p>
          <div className="orange">
            <p>
              Após o envio do pedido, seus dados são analisados e, caso
              aprovado, a transferência é efetuada.
            </p>
            <span>E melhor! O envio dos documentos é totalmente online.</span>
          </div>
        </S.LeftContent>
        <S.RightContent>
          <S.StyledImage fluid={carteira.childImageSharp.fluid} />
        </S.RightContent>
      </S.Wrapper>
    </S.Container>
  )
}

export default ComoFunciona
