import React from "react"

import * as S from "./styles"

function Footer() {
  return (
    <S.Container>
      <S.Wrapper>
        <p>
          A Granacred CNPJ 0965443300135, é Parceira de Promotora e
          correspondente bancário autorizado e certificado pela ANEPS. Somos
          representantes autorizados a realizar empréstimos de quaisquer
          naturezas, cartões de crédito e demais produtos bancários pelos bancos
          BMG, Itaú, Bradesco, Panamericano, Safra, BGN, Bonsucesso, Daycoval,
          Mercantil e promotoras Hcred, Bevicred, Ribercred, dentre outras
          Promotoras. Ressaltamos que a Granacred é uma empresa de intermediação
          financeira. Como correspondente bancário, seguimos as diretrizes do
          Banco Central do Brasil, nos termos da Resolução nº. 3.954, de 24 de
          fevereiro de 2011 e não solicita taxas ou depósitos antecipados em
          nenhuma operação financeira. Informações adicionais: o prazo de
          pagamento das parcelas para Empréstimo no cartão varia de 4 até 12
          meses (de acordo com seu convênio).
        </p>
        <p>
          Simulação da taxa aplicada no cartão de crédito em Julho 2020
          <br />
          *informações gerais. Prazo de pagamento: O período mínimo para
          pagamento é de 4 meses e o máximo de 12 meses. Custo Efetivo Total: O
          Custo Efetivo Total (CET) praticado varia de 22,61% a 488,21% ao ano.
          Exemplo: Valor do crédito de R$7.000,00 para pagar em 12 (doze) meses.
          Total de 12 (doze) parcelas de R$778,74. Detalhes referentes às taxas
          do crédito: Valor total a pagar: R$9.344,88. CET: 4,75% na operação e
          74,55% ao ano. TAC: 6% do Valor solicitado.
        </p>
      </S.Wrapper>
    </S.Container>
  )
}

export default Footer
